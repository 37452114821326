<template>
  <div class="formio-form-wrapper" ref="formio-container">
    <div ref="formIOWidget" v-show="!nodeRedData[constants.thankYou]">
      <div class="loader"></div>
      <p :v-if="errorMsg" v-html="errorMsg"></p>
      <p :v-if="errorMsg" v-html="errorMsg"></p>
    </div>
    <div ref="thankYouMsg" v-if="nodeRedData && nodeRedData[constants.thankYou]"
      v-html="getNodeRedContent(constants.thankYou)">
    </div>

    <div class="disclaimer" v-if="!isEnglishSite() && showDisclaimer">
      <p>
        <span style="color: #252525; font-size: 20px;">*</span> deze velden zijn verplicht.
      </p>
      <br />
      <h3>Disclaimer</h3>
      <p>
        Met jouw gegevens kunnen we je beter informeren over hoe we samen grote
        vervuilers kunnen aansporen tot actie. We bewaren je informatie zorgvuldig
        en volgen de AVG-voorschriften.
        Bekijk onze
        <a href="https://milieudefensie.nl/over-ons/cookies-en-privacy" target="_blank">
          privacybepaling
        </a> voor details.
      </p>
      <p>
        Als je je telefoonnummer hebt ingevuld, kunnen we je maximaal 1 x per jaar bellen
        om je op de hoogte te houden van onze campagnes en hoe jij kan helpen de
        klimaatcrisis te bestrijden.
      </p>
      <small style="display: none" class="version">
        pu-form-componpackaent version: {{ this.version }}
      </small>
    </div>

    <div class="disclaimer" v-if="isEnglishSite() && showDisclaimer">
      <p>
        <span style="color: red; font-size: 20px;">*</span> these fields are mandatory.
        <span style="color: red; font-size: 20px;">*</span> these fields are mandatory.
      </p>
      <br />
      <h3>Disclaimer</h3>
      <p>
        Your information will be saved within our administration. All gathered information
        is managed with care and discretion. Milieudefensie will only use your provided personal
        data to register your donation.
        Read our <a href="https://milieudefensie.nl/over-ons/cookies-en-privacy" target="_blank">
          privacy disclaimer (only available in Dutch)</a> for further details.
      </p>
      <small style="display: none" class="version">
        pu-form-component version: {{ this.version }}
      </small>
    </div>
  </div>
</template>

<script>
import AUTOCOMPLETES from '@/constants/autocomplete';
import FORMIO_TRANSLATIONS from '@/constants/formIOTranslations';
import NODERED_ACTIONS from '@/constants/noderedActions';
import axios from 'axios';
import 'es6-promise/auto';
import 'formiojs';

import {
  AddressAutofillHandler,
  fields as addressFields,
} from '../views/address_handler';

export default {
  name: 'PUForm',
  components: {},

  props: {
    formId: {
      type: String,
      required: true,
    },
    partner: {
      type: String,
      required: false,
    },
    reset: {
      required: false,
    },
    showDisclaimer: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      constants: { ...NODERED_ACTIONS },
      errorMsg: '',
      nodeRedData: {},
      addressAutofillHandler: null,
      addressHandlerActive: false,
      version: process.env.PACKAGE_VERSION || '',
    };
  },

  methods: {
    getNodeRedContent(key) {
      return this.nodeRedData[key].content;
    },

    getNodeRedAttrVal(key, attr) {
      let result = '';
      this.nodeRedData[key].attrs.forEach((el) => {
        if (el.attr === attr) {
          result = el.value;
        }
      });
      return result;
    },

    createFormioForm() {
      let formioUrl = process.env.VUE_APP_FORMIO_URL;

      // add trailing slash if not available
      if (formioUrl.lastIndexOf('/') !== formioUrl.length - 1) {
        formioUrl = `${formioUrl}/`;
      }
      const container = this.$refs.formIOWidget;

      const url = `${formioUrl}${this.formId}`;

      // eslint-disable-next-line no-undef
      Formio.icons = 'fontawesome';

      /*
         Checkout the templates section in the formio.js docs:
         https://github.com/formio/formio.js/wiki/Templates-API
         If we need the wizard tabs back, use: {{ ctx.wizardHeader }}
         in the wizard form template below.
      */

      // eslint-disable-next-line no-undef
      Formio.createForm(container, url, {
        templates: {
          wizard: {
            form: `
              <div style="position: relative;">
                <div class="wizard-page" ref="{{ctx.wizardKey}}">
                  {{ctx.components}}
                </div>
                {{ ctx.wizardNav }}
              </div>
            `,
          },
        },
        settings: {
          recaptcha: {
            isEnabled: true,
            siteKey: '6LcbV6clAAAAABdQRYjq4YdZf5CHfQJ9C2RNLdsz',
          },
        },
        readOnly: false,
        noAlerts: true,
        language: 'nl',
        // breadcrumbSettings: { clickable: false },
        buttonSettings: { showCancel: false, showSubmit: false },
        i18n: FORMIO_TRANSLATIONS,
        hooks: {
          beforeSubmit: (submission, next) => {
            // Hack alert!
            // 1. Make sure the readOnly fields are submitted.
            // 2. Also, when the address_handler changes straatnaam and plaats
            //    the value doesn't end up in the submission data. Therefor,
            //    we also explicitly set these two values.
            const readOnlyElements = container.querySelectorAll(
              'input[name="data[straatnaam]"], input[name="data[plaats]"], .readonly',
            );
            if (readOnlyElements.length) {
              readOnlyElements.forEach((input) => {
                // input.name is something like data[straatnaam]
                const fieldName = /^data\[(.*)\]$/.exec(input.name)[1];
                // eslint-disable-next-line no-param-reassign
                submission.data[fieldName] = input.value;
              });
            }
            next();
          },
        },
      }).then((form) => {
        if (sessionStorage.getItem('lastSubmission')) {
          // eslint-disable-next-line no-param-reassign
          form.submission = {
            data: JSON.parse(sessionStorage.getItem('lastSubmission')),
          };
          // clear the last submit
          sessionStorage.removeItem('lastSubmission');
        }
        // fix readonly fields. form.io doesn't seem to support readonly
        // fields but only disabled fields. A disabled field will not be submitted
        // so is often useless.
        // the workaround is to set a custom class name to the form.io input
        // so we can make the input readonly here
        const readOnlyElements = container.getElementsByClassName('readonly');

        if (readOnlyElements.length) {
          readOnlyElements.forEach((el) => {
            const inputs = el.getElementsByClassName('form-control');
            inputs.forEach((input) => {
              // eslint-disable-next-line no-param-reassign
              input.readOnly = true;
            });
          });
        }

        // Explicitly set the autocomplete attributes.
        // Our version of formio is lacking this functionality.
        const sroot = this.$refs['formio-container'];
        let acShadowedElements = [];

        if (sroot) {
          acShadowedElements = Object.getOwnPropertyNames(AUTOCOMPLETES)
            .flatMap(name => Array.from(sroot.querySelectorAll(`input[name="data[${name}]"]`)));
        }

        const acUnshadowedElements = Object.getOwnPropertyNames(AUTOCOMPLETES)
          .flatMap(name => Array.from(document.querySelectorAll(`input[name="data[${name}]"]`)));

        const autocompleteElements = acShadowedElements.concat(acUnshadowedElements);
        const regex = /\[(.*?)\]/;
        autocompleteElements.forEach((el) => {
          const match = regex.exec(el.name);
          el.setAttribute('autocomplete', AUTOCOMPLETES[match[1]]);
        });

        // Alternatively you could fetch browser location to set lang
        // form.language = window.navigator.language.substring(0, 2);
        window.setLanguage = (lang) => {
          // eslint-disable-next-line no-param-reassign
          form.language = lang;
        };

        form.on('error', (error) => {
          const root = container.getRootNode
            ? container.getRootNode()
            : document;

          const firstErrorElements = root.querySelectorAll(
            `[name='data[${error[0].component.key}]']`,
          );
          if (firstErrorElements.length > 0) {
            firstErrorElements[0].scrollIntoView();
          }
        });

        form.on('toggleNl', () => {
          window.setLanguage('nl');
        });

        form.on('toggleDe', () => {
          window.setLanguage('de');
        });

        window.setLanguage('nl');

        // Only create on form change. We need this, because when we use the wizard
        // style of FormIO instead of the default form style, the postcode field
        // may noy yet be loaded into the DOM.
        form.on('change', () => {
          if (
            !this.addressHandlerActive
            && container.querySelector(
              `input[name="data[${addressFields.postcode}]`,
            )
          ) {
            this.addressAutofillHandler = new AddressAutofillHandler(
              // container.getRootNode(),  // IE snapt dit niet
              container.getRootNode ? container.getRootNode() : document,
            );
            this.addressHandlerActive = true;
          }
        });

        // Submit the form
        form.on('submit', (submission) => {
          const instance = this;

          const noderedSubmission = {
            formPath: this.formId,
            // eslint-disable-next-line no-underscore-dangle
            submissionId: submission._id,
          };
          const puformsubmitDetail = {
            success: true,
          };
          Object.keys(submission.data).forEach((key) => {
            if (key.startsWith('optin')) {
              // eslint-disable-next-line no-param-reassign
              puformsubmitDetail[key] = submission.data[key];
            }
          });

          // which items do we store for re-use
          const reusables = [
            'voornaam',
            'tussenvoegsel',
            'achternaam',
            'postcode',
            'huisnummer',
            'huisnrtoevoeging',
            'straatnaam',
            'plaats',
            'email',
            'optin',
          ];

          Object.keys(submission.data).forEach((key) => {
            if (reusables.indexOf(key) === -1) {
              // eslint-disable-next-line no-param-reassign
              delete submission.data[key];
            }
          });
          sessionStorage.setItem(
            'lastSubmission',
            JSON.stringify(submission.data),
          );

          // insert UTM parameters for marketing/analytics
          const urlParams = new URLSearchParams(window.location.search);
          noderedSubmission.tracking = {};

          urlParams.forEach((value, key) => {
            if (key.startsWith('utm_') || key.toLowerCase().startsWith('act')) {
              noderedSubmission.tracking[key] = value;
            }
          });

          // dispatch custom event
          container.dispatchEvent(new CustomEvent('puformsubmit', {
            bubbles: true,
            composed: true,
            detail: puformsubmitDetail,
          }));

          // submit to NodeRED
          axios
            .post(
              `${process.env.VUE_APP_NODERED_URL}${process.env.VUE_APP_NODERED_SUBMIT_URL}`,
              noderedSubmission,
            )
            .then((response) => {
              instance.nodeRedData = { ...response.data };
              if (instance.nodeRedData[instance.constants.redirect]) {
                const redirectURL = instance.nodeRedData[instance.constants.redirect];
                if (redirectURL) {
                  window.location = redirectURL;
                }
              }
            });
        });
      });
    },
    isEnglishSite() {
      return window.location.hostname.indexOf('en.') !== -1;
    },
  },
  mounted() {
    const root = this.$refs['formio-container'];

    const linkElement = document.createElement('link');

    /* add attributes */
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute(
      'href',
      `${process.env.VUE_APP_STATIC_URL}/pu-form.css`,
    );
    root.appendChild(linkElement);

    this.createFormioForm();
  },

  watch: {
    formId() {
      this.createFormioForm();
    },
    reset() {
      this.createFormioForm();
    },
  },
};
</script>

<style lang="scss">
@import "../scss/partners/milieudefensie.scss";
@import "../scss/partners/custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~formiojs/dist/formio.full.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Bootstrap 3 stuff that is no longer included in Bootstrap 4, but is still used in FormIO
.btn-xs {
  @include button-size($input-btn-padding-y * 0.75,
    $input-btn-padding-x * 0.75,
    $font-size-base * 0.75,
    $line-height-base * 0.75,
    $border-radius );
}


.version {
  color: rgba(0, 0, 0, 0.1);
}


.disclaimer {
  font-size: 1.45rem;

  h3 {
    font-size: 2rem;
  }
}

.invalid-feedback {
  font-size: 95% !important;
}


.field-required:after {
  color: #252525 !important;
}
</style>
